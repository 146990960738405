<script setup>
import { ref } from "vue";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Registries from "@/components/debtors-registries/registries.vue";
import RegistryRows from "@/components/debtors-registries/rows.vue";

const selectedRegistryId = ref(0);
function onRowSelected(row) {
  selectedRegistryId.value = row.id;
}
</script>

<template>
  <Layout>
    <PageHeader title="Рабочие реестры" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col d-flex flex-column gap-5">
            <Registries @row-selected="onRowSelected" />
            <RegistryRows :registryId="selectedRegistryId" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
