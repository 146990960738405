<script setup>
import { ref, defineEmits } from "vue";
import { usersStore } from "@/helpers/common-store/store";
import { DxForm, DxFormItem, DxLabel, DxPopup, DxRequiredRule, DxSelection } from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";

import DxTagBox from "devextreme-vue/tag-box";
import settings from "@/helpers/grid/settings.js";
import createStoreExtend from "@/helpers/grid/store";

const emits = defineEmits(["rowSelected"]);

const gvRegistries = ref();
const registriesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRIES_GET}`,
  insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRIES_CREATE}`,
  updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRIES_UPDATE}`,
  deleteUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRIES_DELETE}`,
});

function onUsersChanged(e, data) {
  data.setValue(e.value);
}

function cellUsers(data) {
  const noBreakSpace = "\u00A0";
  const text = (data.value || []).map((e) => data.column.lookup.calculateCellValue(e)).join(", ");
  return text || noBreakSpace;
}

function onToolbarPreparing(e) {
  let toolbarItems = e.toolbarOptions.items;
  let addRowButton = toolbarItems.find((x) => x.name === "addRowButton");
  addRowButton.options.text = "Добавить реестр";
  addRowButton.showText = "always";

  toolbarItems.push({
    location: "before",
    template: function () {
      return "<h6 class='pe-2 mb-0 text-nowrap'>Реестры:</h6>";
    },
  });
}

function onRowSelected(data) {
  emits("rowSelected", data.selectedRowsData[0]);
}
</script>

<template>
  <DxDataGrid
    id="gvRegistries"
    ref="gvRegistries"
    v-bind="settings"
    :data-source="registriesStore"
    @toolbar-preparing="onToolbarPreparing($event)"
    @selection-changed="onRowSelected"
  >
    <DxColumn data-field="id" caption="Номер реестра" width="120" sort-order="desc" :sort-index="0" />
    <DxColumn data-field="description" caption="Задание" :visible="false">
      <DxRequiredRule />
    </DxColumn>
    <DxColumn data-field="name" caption="Название реестра">
      <DxRequiredRule />
    </DxColumn>

    <DxColumn data-field="createdBy" caption="Автор">
      <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
    </DxColumn>
    <DxColumn data-field="created" caption="Создан" width="120" data-type="date" />
    <DxColumn
      edit-cell-template="editExecutionUsersTemplates"
      cell-template="cellExecutionUsersTemplates"
      data-field="executionUsersIds"
      caption="Исполнители"
    >
      <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
      <DxFormItem :col-span="2">
        <DxLabel location="top" text="Исполнители" />
      </DxFormItem>
    </DxColumn>

    <!-- Templates -->
    <template #cellExecutionUsersTemplates="{ data }">
      {{ cellUsers(data) }}
    </template>

    <template #editExecutionUsersTemplates="{ data: rowInfo }">
      <DxTagBox
        :data-source="usersStore"
        :show-selection-controls="true"
        :value="rowInfo.data.executionUsersIds"
        :search-enabled="true"
        @value-changed="(e) => onUsersChanged(e, rowInfo)"
        display-expr="name"
        value-expr="id"
      />
    </template>

    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
      <DxPopup :show-title="true" title="Создание реестра" width="800" height="auto" />
      <DxForm label-location="top">
        <DxItem data-field="name" :col-span="2" />
        <DxItem data-field="description" :col-span="2" />
        <DxItem data-field="executionUsersIds" :col-span="2" />
      </DxForm>
    </DxEditing>
    <DxSelection mode="single" />
    <DxScrolling column-rendering-mode="virtual" :use-native="true" show-scrollbar="true" />
    <DxPaging :page-size="5" :enabled="true" />
    <DxFilterRow :visible="true" />
    <DxPager
      :visible="true"
      :allowed-page-sizes="[5, 10, 20, 50]"
      :show-page-size-selector="true"
      :show-navigation-buttons="true"
      :show-info="true"
    />
  </DxDataGrid>
</template>
