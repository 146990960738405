<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";
import { DxSelection } from "devextreme-vue/data-grid";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxForm, DxSimpleItem, DxLabel, DxGroupItem } from "devextreme-vue/form";
import { authHeader } from "@/helpers/authservice/auth-header";
import { useDebtorsRegistriesStore } from "@/state/debtors.registries";
import DxTagBox from "devextreme-vue/tag-box";

import settings from "@/helpers/grid/settings.js";
import createStoreExtend from "@/helpers/grid/store";

const store = useDebtorsRegistriesStore();

const registryRowsStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRY_ROWS_TO_ADD_GET}`,
  onBeforeSend: (_method, ajaxOptions) => {
    ajaxOptions.data.registryId = props.registryId;
    ajaxOptions.data.criterias = JSON.stringify(formData.value);
  },
});

const props = defineProps({
  registryId: {
    default: 0,
  },
  showPopup: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update:showPopup", "debtorsAdded"]);

const form = ref();
const gvRegistryRowsToAdd = ref();
const selectedRows = ref([]);
const message = ref(null);
const registryId = computed(() => props.registryId);
const totalRowsCount = ref(0);
const isDisabled = computed(() => registryId.value == 0 || totalRowsCount.value == 0);
const isAnySelected = computed(() => selectedRows.value.length != 0);

const formData = ref({
  housesIds: null,
  debtFrom: null,
  debtTo: null,
  monthNumberFrom: null,
  monthNumberTo: null,
  notShowAlreadyExists: true,
});

function cancel() {
  gvRegistryRowsToAdd.value.instance.option("dataSource", null);
  emits("update:showPopup", false);
  resetForm();
}

function resetForm() {
  form.value.instance.resetValues();
  formData.value.notShowAlreadyExists = true;
}

const housesData = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_HOUSES_GET}`,
  onBeforeSend: (_method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
    ajaxOptions.headers = authHeader();
  },
});

function onContentReady(e) {
  totalRowsCount.value = e.component.totalCount();
}

function find() {
  totalRowsCount.value = 0;
  gvRegistryRowsToAdd.value.instance.option("dataSource", registryRowsStore);
  formData.value.notIncludeAlreadyInRegistryId = null;
  if (formData.value.notShowAlreadyExists) {
    formData.value.notIncludeAlreadyInRegistryId = registryId.value;
  }

  gvRegistryRowsToAdd.value.instance.refresh();
}

async function addAll() {
  await store.addByCriterias(props.registryId, formData.value);
  find();
  emits("debtors-added");
}

async function addSelected() {
  await store.addByIds(props.registryId, selectedRows.value);
  find();
  emits("debtors-added");
}
</script>

<template>
  <div>
    <DxPopupModal
      id="createOrUpdateInhabitant"
      :visible="showPopup"
      :drag-enabled="true"
      :show-title="true"
      title="Добавление должников в реестр"
      width="90%"
      height="90%"
      @hidden="cancel"
    >
      <DxPopupPosition at="center" my="center" />
      <DxScrollView>
        <div class="alert" :class="[classes]" v-if="message">
          {{ message }}
        </div>

        <div class="d-flex flex-column gap-2">
          <form @submit="save">
            <DxForm :form-data="formData" ref="form">
              <DxGroupItem :col-count="2">
                <DxSimpleItem data-field="housesIds" template="houses" :col-span="2">
                  <DxLabel text="Дома" />
                </DxSimpleItem>

                <DxSimpleItem data-field="debtFrom" editor-type="dxNumberBox" :editor-options="{ showClearButton: true }">
                  <DxLabel text="Сумма долга от" />
                </DxSimpleItem>
                <DxSimpleItem data-field="debtTo" editor-type="dxNumberBox" :editor-options="{ showClearButton: true }">
                  <DxLabel text="до" />
                </DxSimpleItem>

                <DxSimpleItem data-field="monthNumberFrom" editor-type="dxNumberBox" :editor-options="{ showClearButton: true }">
                  <DxLabel text="Кол-во месяцво от" />
                </DxSimpleItem>
                <DxSimpleItem data-field="monthNumberTo" editor-type="dxNumberBox" :editor-options="{ showClearButton: true }">
                  <DxLabel text="до" />
                </DxSimpleItem>
                <DxSimpleItem data-field="notShowAlreadyExists" editor-type="dxCheckBox" :editor-options="{ showClearButton: true }">
                  <DxLabel text="Не показывать уже включенных" />
                </DxSimpleItem>
              </DxGroupItem>
              <template #houses>
                <DxTagBox
                  v-model:value="formData.housesIds"
                  :data-source="housesData"
                  :show-selection-controls="true"
                  :search-enabled="true"
                  :max-displayed-tags="5"
                  display-expr="address"
                  value-expr="id"
                />
              </template>
            </DxForm>
          </form>

          <div class="ml-auto align-self-end">
            <DxButton text="Найти" width="120px" @click="find" />
          </div>

          <DxDataGrid
            id="gvRegistryRowsToAdd"
            ref="gvRegistryRowsToAdd"
            v-bind="settings"
            v-model:selected-row-keys="selectedRows"
            @content-ready="onContentReady"
          >
            <DxColumn data-field="streetName" caption="Улица" width="180" sort-order="asc" :sort-index="0" />
            <DxColumn data-field="houseNumber" caption="Дом" width="60" sort-order="asc" :sort-index="1" />
            <DxColumn data-field="buildingNumber" caption="Корп." width="60" sort-order="asc" :sort-index="2" />
            <DxColumn data-field="flatNumber" caption="Кв." width="60" />
            <DxColumn data-field="accountNumber" caption="ЛС" width="100" />
            <DxColumn data-field="includingDebt" caption="Долг на момент включения">
              <DxFormat type="fixedPoint" :precision="2" />
            </DxColumn>
            <DxColumn data-field="includingMonthNumber" caption="Кол-во мес. на момент включения">
              <DxFormat type="fixedPoint" :precision="2" />
            </DxColumn>

            <DxSelection mode="multiple" show-check-boxes-mode="always" />
            <DxScrolling column-rendering-mode="virtual" :use-native="true" show-scrollbar="true" />
            <DxPaging :page-size="15" :enabled="true" />
            <DxFilterRow :visible="true" />
            <DxPager
              :visible="true"
              :allowed-page-sizes="[5, 10, 15, 20, 50]"
              :show-page-size-selector="true"
              :show-navigation-buttons="true"
              :show-info="true"
            />
          </DxDataGrid>

          <div class="d-flex gap-2 justify-content-between">
            <div class="d-flex gap-2">
              <DxButton text="Добавить выбранных" type="default" :disabled="isDisabled || !isAnySelected" @click="addSelected" />
              <DxButton text="Добавить всех" type="default" :disabled="isDisabled" @click="addAll" />
            </div>
            <DxButton text="Отменить" type="danger" @click="cancel" />
          </div>
        </div>
      </DxScrollView>
    </DxPopupModal>
  </div>
</template>
