import axios from "axios";
import { defineStore } from "pinia";
import notify from "devextreme/ui/notify";

const addByCriteriasUrl = `${process.env.VUE_APP_URL}/api/devextreme/debtors-registry-rows/add-debtors-by-criterias`;
const addByDebtorsIdsUrl = `${process.env.VUE_APP_URL}/api/devextreme/debtors-registry-rows/add-debtors-by-ids`;
const excludeDebtorsUrl = `${process.env.VUE_APP_URL}/api/devextreme/debtors-registry-rows/delete`;

export const useDebtorsRegistriesStore = defineStore({
    id: "debtorsRegistries",
    state: () => ({
        isLoading: false,
        error: null,
        selectedDebtorsRows: [],
    }),
    getters: {
        executionDocumentsOffice(state) {
            return state.executionDocuments.filter((r) => r.current.data.dataType == 1);
        },
    },
    actions: {
        resetError() {
            this.error = null;
        },

        async addByCriterias(registryId, criterias) {
            await axios.post(addByCriteriasUrl, {
                registryId: registryId,
                criterias: criterias
            });

            notify({
                message: "Должники добавлены",
                height: 40,
                width: 190,
                minWidth: 190,
                position: {
                    my: "bottom left",
                    at: "bottom left",
                    offset: '20 -20'
                },
                displayTime: 3500
            }, "success");
        },
        async addByIds(registryId, debtorsIds) {
            await axios.post(addByDebtorsIdsUrl, {
                registryId: registryId,
                debtorsIds: debtorsIds
            });

            notify({
                message: "Должники добавлены",
                height: 40,
                width: 190,
                minWidth: 190,
                position: {
                    my: "bottom left",
                    at: "bottom left",
                    offset: '20 -20'
                },
                displayTime: 3500
            }, "success");
        },

        async excludeDebtors(registryId, rowsIds) {
            await axios.post(excludeDebtorsUrl, {
                registryId: registryId,
                rowsIds: rowsIds
            });

            notify({
                message: "Должники исключены",
                height: 40,
                width: 190,
                minWidth: 190,
                position: {
                    my: "bottom left",
                    at: "bottom left",
                    offset: '20 -20'
                },
                displayTime: 3500
            }, "success");
        },
    },
});
