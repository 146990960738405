<script setup>
import { ref, defineProps, watch, computed } from "vue";
import { DxSelection } from "devextreme-vue/data-grid";
import { confirm } from "devextreme/ui/dialog";
import AddDebtorsPopup from "./add-debtors-popup.vue";
import settings from "@/helpers/grid/settings.js";
import createStoreExtend from "@/helpers/grid/store";
import { useDebtorsRegistriesStore } from "@/state/debtors.registries";

const store = useDebtorsRegistriesStore();

const props = defineProps({
  registryId: {
    default: 0,
  },
});

const gvRegistryRows = ref();
const showAddDebtorsPopup = ref(false);

const registryRowsStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_REGISTRY_ROWS_GET}`,
  onBeforeSend: (_method, ajaxOptions) => {
    ajaxOptions.data.registryId = props.registryId;
  },
});

watch(
  () => props.registryId,
  () => {
    refreshGrid();
  }
);

const selectedRows = ref([]);
const isAddDisabled = computed(() => props.registryId == 0);
const isExcludeDisabled = computed(() => selectedRows.value.length == 0);

function refreshGrid() {
  gvRegistryRows.value.instance.refresh();
  selectedRows.value = [];
}

function excludeSelected() {
  let result = confirm(
    "<span class='fs-5 align-middle'>Вы уверены, что хотите исключить должников из реестра ?</span>",
    "Исключение должников"
  );
  result.then(async (dialogResult) => {
    if (dialogResult) {
      await store.excludeDebtors(props.registryId, selectedRows.value);
      refreshGrid();
    }
  });
}

function onDebtorsAdded() {
  showAddDebtorsPopup.value = false;
  refreshGrid();
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <h6>Детализация:</h6>
      <DxButton text="Добавить должников" icon="plus" type="default" :disabled="isAddDisabled" @click="showAddDebtorsPopup = true" />
    </div>
    <DxDataGrid
      id="gvRegistryRows"
      ref="gvRegistryRows"
      v-bind="settings"
      v-model:selected-row-keys="selectedRows"
      :data-source="registryRowsStore"
    >
      <DxColumn data-field="streetName" caption="Улица" width="180" sort-order="asc" :sort-index="0" />
      <DxColumn data-field="houseNumber" caption="Дом" width="60" sort-order="asc" :sort-index="1" />
      <DxColumn data-field="buildingNumber" caption="Корп." width="60" sort-order="asc" :sort-index="2" />
      <DxColumn data-field="flatNumber" caption="Кв." width="60" />
      <DxColumn data-field="accountNumber" caption="ЛС" width="100" cell-template="accountNumberTemplate" />
      <DxColumn data-field="includingDebt" caption="Долг на момент включения">
        <DxFormat type="fixedPoint" :precision="2" />
      </DxColumn>
      <DxColumn data-field="includingMonthNumber" caption="Кол-во мес. на момент включения">
        <DxFormat type="fixedPoint" :precision="2" />
      </DxColumn>

      <template #accountNumberTemplate="{ data }">
        <router-link :to="{ name: 'debtor-card', params: { id: data.data.debtorId } }">
          {{ data.value }}
        </router-link>
      </template>

      <DxSelection mode="multiple" show-check-boxes-mode="always" />
      <DxScrolling column-rendering-mode="virtual" :use-native="true" show-scrollbar="true" />
      <DxPaging :page-size="20" :enabled="true" />
      <DxFilterRow :visible="true" />
      <DxPager
        :visible="true"
        :allowed-page-sizes="[5, 10, 20, 50]"
        :show-page-size-selector="true"
        :show-navigation-buttons="true"
        :show-info="true"
      />
    </DxDataGrid>
    <DxButton text="Исключить выбранных" type="danger" @click="excludeSelected" :disabled="isExcludeDisabled" />
    <AddDebtorsPopup :registry-id="registryId" v-model:show-popup="showAddDebtorsPopup" @debtors-added="onDebtorsAdded" />
  </div>
</template>
